import React from "react"
import Layout from "../components/layout"
      //<p><Link to="/contact/">Contact</Link></p>

export default function Home() {
  return (
    <Layout>
      <h1>Kumquat</h1>
      <img src="https://source.unsplash.com/random/400x200" alt="" />
      <p>The Cucumber and Mushroom Submarine Growing Guide</p>
    </Layout>
  );
}

